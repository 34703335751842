
.clm-description
	padding: 20px
	background-color: $color-white
	font-family: "Rubik", sans-serif
	font-size: 15px
	line-height: normal
	color: $color-black
	@media (max-width: $media-mobile-max)
		padding: 0

	& *
		box-sizing: border-box

	// .clm-description__chess
	&__chess:not(:last-child) 
		margin-bottom: 50px
		@media (max-width: $media-mobile-max)
			&:not(:last-child)
				margin-bottom: 30px

	// .clm-description__recommended
	&__recommended 
		margin-bottom: 50px
		
	// .clm-description__questions
	&__questions 
		margin-bottom: 50px
		@media (max-width: $media-mobile-max)
			margin-bottom: 30px

	& .flex-revers
		flex-direction: row-reverse

	// .clm-description__list
	&__list p 
		font-size: 14px

	// .clm-description__line
	&__line
		display: flex
		align-items: center
		margin: 0 -20px
		&:not(:last-child) 
			margin-bottom: 50px

		@media (max-width: $media-mobile-max)
			display: block
			margin: 0
		
			&:not(:last-child) 
				margin-bottom: 30px

		body .clm-description .clm-description__chess & p
			margin-top: 0
			font-family: 'Rubik'
			font-weight: 500
			font-size: 15px
			line-height: 130%
			color: $color-black

		.clm-description & p ~ p
			margin-top: 0

		.clm-description & p:not(:last-child)
			margin: 0 0 10px 0

	// .clm-description__item-line
	&__item-line
		flex: 0 0 50%
		padding: 0 20px
		&:first-child 
			align-self: flex-start

		@media (max-width: $media-mobile-max)
			padding: 0
			&:not(:last-child)
				margin-bottom: 20px

	// .clm-description__video
	&__video
		position: relative
		padding-bottom: 56.25%

		& video
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%

	// .clm-description__title
	&__title
		font-weight: 800
		font-size: 35px
		color: $color-primary-dark

		&:not(:last-child)
			margin-bottom: 20px

		@media (max-width: $media-mobile-max)
			font-size: 23px

			&:not(:last-child) 
				margin-bottom: 10px

	// .clm-description__img
	&__img
		position: relative
		padding-bottom: 56.25%

		@media (max-width: $media-mobile-max)
			height: 220px

		& img
			position: absolute
			width: 100%
			height: 100%
			object-fit: cover

	// .clm-description__list
	&__list
		list-style: inside
		counter-reset: num

	// .clm-description__item
	&__item
		display: flex
		counter-increment: num
		&:not(:last-child) 
			margin-bottom: 20px

	// .clm-description__counter-side
	&__counter-side
		display: flex
		align-items: center
		flex-direction: column
		margin: 0px 25px 0 0

		.clm-description__item:not(:last-child) &::after
			content: ''
			flex: 1 1 auto
			width: 1px
			height: 100%
			border-left: 1px dashed $color-primary-light
			transform: translateY(10px)

		@media (max-width: $media-mobile-max)
			margin: 0 20px 0 0

	// .clm-description__counter
	&__counter
		display: flex
		align-items: center
		justify-content: center
		flex: 0 0 24px
		width: 24px
		height: 24px
		border-radius: 50%
		background-color: $color-primary-dark
		&:before 
			content: counter(num)
			font-family: "Rubik", sans-serif
			font-weight: 500
			font-size: 15px
			line-height: 1
			color: $color-white

	// .clm-description__main
	&__main
		margin-top: 40px
		font-weight: 700
		font-size: 23px
		color: $color-primary-dark
		@media (max-width: $media-mobile-max)
			margin-top: 20px
			font-size: 17px

	// .clm-description__statistic
	&__statistic
		margin-bottom: 50px
		@media (max-width: $media-mobile-max)
			margin-bottom: 30px

.statistic-desc
	position: relative
	padding: 50px
	@media (max-width: $media-mobile-max)
		padding: 0

	// .statistic-desc__img
	&__img
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		@media (max-width: $media-mobile-max)
			position: static
			height: 220px

		img
			width: 100% !important
			height: 100% !important
			object-fit: cover

	// .statistic-desc__body
	&__body
		position: relative
		z-index: 10
		min-height: 700px
		display: flex
		flex-direction: column
		align-items: center
		justify-content: space-between
		@media (max-width: $media-mobile-max)
			position: static
			min-height: 0

	// .statistic-desc__item
	&__item
		border-radius: 50px
		display: flex
		flex-direction: column
		align-items: center
		justify-content: center
		min-width: 500px
		padding: 20px
		background-color: rgba(255, 255, 255, 0.8)
		@media (max-width: $media-mobile-max)
			min-width: 0
			padding: 20px 0
	
			&.big
				min-width: 0

		&.big
			min-width: 580px



		

	// .statistic-desc__text
	&__text
		font-weight: 700
		font-size: 35px
		color: $color-primary-dark
		@media (max-width: $media-mobile-max)
			font-size: 23px
	
			&.small
				font-size: 17px

			.statistic-desc__item.big &.small
				margin-top: 10px

			&.big
				font-size: 39px

		.statistic-desc__item.big &:first-child
			margin-bottom: 10px

		.statistic-desc__item.big &.small
			margin-top: 30px

		.statistic-desc__item &.small:not(:last-child) 
			margin-bottom: 10px

		&.big
			font-size: 47px

		&.rubik
			font-family: "Rubik", sans-serif

		&.small
			font-weight: 400
			font-size: 27px

		&.bold
			font-weight: 600

	// .statistic-desc__icons
	&__icons
		margin-bottom: 20px
		display: flex

	// .statistic-desc__icon
	&__icon
		flex: 0 0 60px
		width: 60px
		height: 60px

		svg path
			fill: $color-primary-dark

		&:not(:last-child)
			margin-right: 20px

		& img
			width: 100%
			height: 100%
			object-fit: cover

		@media (max-width: $media-mobile-max)
			flex: 0 0 50px
			width: 50px
			height: 50px
	
			&:not(:last-child)
				margin-right: 10px


.recommended-desc

	// .recommended-desc__wrapper
	&__wrapper

	// .recommended-desc__title
	&__title
		margin-bottom: 30px
		text-align: center
		font-weight: 800
		font-size: 35px
		line-height: 42px
		color: $color-primary-dark
		@media (max-width: $media-mobile-max)
			margin-bottom: 20px
			font-size: 23px

	// .recommended-desc__list
	&__list
		display: grid
		grid-template-columns: repeat(3,1fr)
		gap: 40px
		@media (max-width: $media-mobile-max)
			display: block

	// .recommended-desc__item
	&__item
		@media (max-width: $media-mobile-max)
			&:not(:last-child) 
				margin-bottom: 15px

		body .recommended-desc__list & p
			margin-top: 3px
			text-align: center
			font-size: 14px
			line-height: normal
			color: $color-black

	// .recommended-desc__img
	&__img
		position: relative
		padding-bottom: 56.25%
		margin-bottom: 15px

		& img
			position: absolute
			width: 100%
			height: 100%
			object-fit: cover

	// .recommended-desc__name
	&__name
		font-weight: 700
		font-size: 19px
		line-height: 23px
		text-align: center
		@media (max-width: $media-mobile-max)
			font-size: 17px

.questions-desc

	// .questions-desc__wrapper
	&__wrapper

	// .questions-desc__title
	&__title
		margin-bottom: 30px
		font-weight: 800
		font-size: 35px
		line-height: 42px
		text-align: center
		color: $color-primary-dark
		@media (max-width: $media-mobile-max)
			margin-bottom: 20px
			font-size: 23px

	// .questions-desc__list
	&__list

	// .questions-desc__item
	&__item
		border-bottom: 1px solid rgba(49, 49, 49, 0.1)
		padding-bottom: 30px

		&:not(:last-child)
			margin-bottom: 30px

		&.active .questions-desc__header .questions-desc__img img:first-child 
			opacity: 1

		&.active .questions-desc__header .questions-desc__img img:last-child 
			opacity: 0

		&.active .questions-desc__header .questions-desc__img svg:first-child 
			opacity: 1

		&.active .questions-desc__header .questions-desc__img svg:last-child 
			opacity: 0

		@media (any-hover: hover)
			&.active .questions-desc__header:hover .questions-desc__hover-img img:first-child
				opacity: 1
				
			&.active .questions-desc__header:hover .questions-desc__hover-img img:last-child 
				opacity: 0

			&.active .questions-desc__header:hover .questions-desc__hover-img svg:first-child
				opacity: 1
				
			&.active .questions-desc__header:hover .questions-desc__hover-img svg:last-child 
				opacity: 0
		
	// .questions-desc__header
	&__header
		display: flex
		align-items: center
		cursor: pointer
		@media (any-hover: hover)
			&:hover
				.questions-desc__img img:first-child
					opacity: 0

				.questions-desc__hover-img img:last-child
					opacity: 1

				.questions-desc__img svg:first-child
					opacity: 0

				.questions-desc__hover-img svg:last-child
					opacity: 1

	// .questions-desc__text-header
	&__text-header
		margin-right: 20px
		font-weight: 700
		font-size: 19px
		line-height: 23px
		color: $color-primary-dark
		@media (max-width: $media-mobile-max)
			font-size: 17px

	// .questions-desc__button
	&__button
		position: relative
		flex: 0 0 30px
		width: 30px
		height: 30px
		margin-left: auto

		& img
			margin-top: 0 !important
			margin-bottom: 0 !important

	// .questions-desc__img
	&__img

		& img
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			width: 100%
			height: 100%
			object-fit: contain
			transition: all 0.2s ease 0s
			opacity: 0

		& img:last-child
			opacity: 1

		& svg
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			width: 100%
			height: 100%
			object-fit: contain
			transition: all 0.2s ease 0s
			opacity: 0

			circle
				stroke: $color-primary-dark
			rect
				fill: $color-primary-dark

		& svg:last-child
			opacity: 1

	// .questions-desc__hover-img
	&__hover-img

		& img
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			opacity: 0
			transition: all 0.2s ease 0s

		& svg
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			opacity: 0
			transition: all 0.2s ease 0s

			circle
				fill: $color-primary-dark
				stroke: $color-primary-dark

				


	// .questions-desc__body
	&__body
		font-family: "Rubik", sans-serif
		max-width: 700px
		padding: 10px 0 0px 0
		font-weight: 500
		margin-right: 50px
		font-size: 15px
		line-height: 130%
		@media (max-width: $media-mobile-max)
			padding: 5px 0 0 0
	
			.questions-desc &
				font-size: 14px
			
			.questions-desc & p
				font-size: 14px

		body .clm-description .questions-desc & p
			margin-top: 0
			font-family: "Rubik", sans-serif
			font-weight: 500
			font-size: 15px
			line-height: 130%
			color: $color-black

		.questions-desc & p:not(:last-child)
			margin-bottom: 10px

.help-desc

	// .help-desc__wrapper
	&__wrapper

	// .help-desc__title
	&__title
		margin-bottom: 30px
		font-weight: 800
		font-size: 35px
		line-height: 42px
		text-align: center
		color: $color-primary-dark
		@media (max-width: $media-mobile-max)
			margin-bottom: 20px
			font-size: 23px
			line-height: 28px

	// .help-desc__color
	&__color
		display: inline-block
		margin-bottom: 5px
		color: $color-black

	// .help-desc__line
	&__line
		display: grid
		grid-template-columns: repeat(3,1fr)
		gap: 40px
		@media (max-width: $media-mobile-max)
			display: block

	// .help-desc__item
	&__item
		display: flex
		flex-direction: column
		align-items: center

		&::after 
			display: none

		@media (max-width: $media-mobile-max)
			&:not(:last-child)
				margin-bottom: 30px

	// .help-desc__img
	&__img
		width: 100px
		height: 100px
		margin-bottom: 10px

		& img
			width: 100%
			height: 100%
			object-fit: contain

	// .help-desc__text
	&__text
		font-weight: 700
		font-size: 15px
		line-height: 130%
		text-align: center

		&:not(:last-child)
			margin-bottom: 10px

	// .help-desc__phone
	&__phone
		font-family: "Rubik", sans-serif
		font-weight: 700
		font-size: 19px
		line-height: 130%
		text-align: center
		color: $color-primary-dark